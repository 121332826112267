export default {
  computed: {
    getStatusColor(){
      return (status) => status ? "primary" : "warning"
    },
  },
  methods: {
    absoluteUrl(path = ""){
      return this.$store.state.domain + path
    },
    isImageFile(ext){
      return ['jpeg','jpg','svg','png','webp','gif'].includes(ext)
    },
    scrollToReview(elID){
      document.getElementById(elID).scrollIntoView({behavior: "smooth"})
    },
    preText(content) {
      if(!content) return;
      const words = content.replace(/\n/g, ' <br>').split(' ');
      const formattedWords = [];
      words.forEach(word => {
        if (word.startsWith('http://') || word.startsWith('https://')  || word.startsWith('www.')) {
          formattedWords.push(`<a href="${word}" class="text-success" target="_blank"> ${word} </a>`);
        } else {
          formattedWords.push(word)
        }
      });
      return formattedWords.join(' ')
    },
    alertSuccess(text){
      this.$vs.notify({
      title:'Success',
      text:text,
      color:'success',
      iconPack: 'feather',
      icon:'icon-check',
      position:'top-right'})
    },
    alertError(text){
      this.$vs.notify({
      title:'Error',
      text:text,
      color:'warning',
      iconPack: 'feather',
      icon:'icon-alert-circle',
      position:'top-right'})
    },
  }
};
